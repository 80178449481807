import React from "react";
import { RouteComponentProps } from "@reach/router";
import Bloc from "./Bloc";
import Switch from "ui/Switch";
import BlocJobs from "./BlocJobs2";

const prices = [
  {
    name: "Tarifs abonnement entreprises",
    items: [
      {
        title: "START",
        price: { months: "350 000 FCFA", years: "600 000 FCFA" },
        items: [
          `
        Création espace dédié
          (référencement,
          espace de gestion, historique des
          offres d'emploi et des
          candidatures)
        `,
          `Accès prioritaire évènements et
            diffusion annonceurs`,

          `Accès candidat(es) et gestion
            directe des candidatures (tri,
            traitement )`,
        ],
      },
      {
        title: "STANDARD",
        price: { months: "500 000 FCFA", years: "850 000 FCFA" },
        items: [
          `
        Création espace dédié
          (référencement,
          espace de gestion, historique des
          offres d'emploi et des
          candidatures)
        `,
          `Accès prioritaire évènements et
            diffusion annonceurs`,

          `Accès candidat(es) et gestion
            directe des candidatures (tri,
            traitement )`,

          `Accès illimité CVthèque`,
        ],
      },
      {
        title: "PREMIUM",
        price: { months: "600 000 FCFA", years: "1150 000 FCFA" },
        items: [
          `
        Création espace dédié
          (référencement,
          espace de gestion, historique des
          offres d'emploi et des
          candidatures)
        `,
          `Accès prioritaire évènements et
            diffusion annonceurs`,

          `Accès candidat(es) et gestion
            directe des candidatures (tri,
            traitement )`,

          `Accès illimité CVthèque`,
          `20 Annonces diffusées à la une
sur le portail web`,
          `Diffusion réseaux sociaux
Studies`,
        ],
      },
      // {
      //   title: "TPE,PME,Entrepreneur",
      //   price: {
      //     months:
      //       "Sur mesure, Gratuit pour entrepreneur et petites entreprises",
      //     years: "Sur mesure, Gratuit pour entrepreneur et petites entreprises",
      //   },
      //   items: [
      //     `
      //     Création espace dédié (référencement, espace de gestion, historique des offres d'emploi et des candidatures)
      //   `,
      //     `Accès prioritaire évènements et diffusion annonceurs`,

      //     `Accès candidat(es) et gestion directe des candidatures (tri, traitement )`,
      //   ],
      // },
    ],
  },
];

const Section = ({ price }: { price: any }) => {
  const [by, setBy] = React.useState(true);
  return (
    <div className="container py-5">
      <header className="text-center mb-3 text-white">
        <div className="row">
          <div className="col-lg-12 mx-auto">
            <h1 className="rouded-bg">{price.name}</h1>
            <p style={{ fontSize: 16 }}>
              <i>
                Attention offres réservées aux entreprises uniquement, cabinets
                de recrutement, veuillez nous contacter au 6 20 31 81 67 pour
                plus de renseignements.{" "}
              </i>
            </p>
            <br />
            <p className="text-center">
              <Switch
                primary={{
                  label: "6 mois",
                  checked: by,
                }}
                secondary={{
                  label: "12 mois",
                  checked: !by,
                }}
                onChange={(check) => {
                  setBy(check);
                }}
              />
            </p>
          </div>
        </div>
      </header>
      <div className="row text-center justify-content-center">
        {price.items.map((item: any) => (
          <BlocJobs
            by={by ? "months" : "years"}
            title={item.title}
            price={item.price}
            items={item.items}
            selected={item?.selected}
          />
        ))}
      </div>
    </div>
  );
};

export default function JobsAdsPricing() {
  return (
    <section className="text-center">
      {prices.map((price, key) => (
        <Section price={price} />
      ))}
      <h3 className="text-center rouded-bg">OFFRE PONCTUELLE</h3>
      <br />
      <br />
      <table className="table table-bordered table-hover table-striped table-light">
        <thead className="thead-light">
          <tr>
            <th scope="col">Offre</th>
            <th scope="col">01 mois</th>
            <th scope="col">03 mois</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Diffusion offre d'emploi et accès illimité candidatures</td>
            <td>65 000 FCFA</td>
            <td>200 000 FCFA</td>
          </tr>
          <tr>
            <td>Option CVthèque</td>
            <td>+ 40 000 FCFA</td>
            <td>+ 40 000 FCFA</td>
          </tr>
          <tr>
            <td>Diffusion à la une</td>
            <td>+ 20 000 FCFA</td>
            <td>+ 20 000 FCFA</td>
          </tr>
          <tr>
            <td>Diffusion des réseaux sociaux</td>
            <td>+ 25 000 FCFA</td>
            <td>+ 25 000 FCFA</td>
          </tr>
        </tbody>
      </table>
    </section>
  );
}
